import React from 'react';
import { DateTime } from 'luxon';
import { MultiGrid } from 'react-virtualized';
import { formatToFixed } from '../../helpers/format';
import {
  getWindDirection,
  getMillibarsFromSeaLevelPressure
} from '../../helpers/weather';

const columns = [
  {
    name: 'Date',
    value: 'dateTime',
    format: dateTime =>
      DateTime.fromMillis(dateTime * 1000).toFormat('MM/dd/yy')
  },
  {
    name: 'Time',
    value: 'dateTime',
    format: dateTime => DateTime.fromMillis(dateTime * 1000).toFormat('h:mma')
  },
  {
    name: 'Temperature',
    value: 'outTemp',
    format: temperature => `${formatToFixed(temperature, 1)}°F`
  },
  {
    name: 'Humidity',
    value: 'outHumidity',
    format: humidity => `${humidity}%`
  },
  {
    name: 'Dewpoint',
    value: 'dewpoint',
    format: dewpoint => `${formatToFixed(dewpoint, 1)}°F`
  },
  {
    name: 'Wind Speed',
    value: 'windSpeed',
    format: windSpeed => `${formatToFixed(windSpeed, 1)} mph`
  },
  {
    name: 'Wind Dir',
    value: 'windDir',
    format: degrees => getWindDirection(degrees)
  },
  {
    name: 'Wind Chill',
    value: 'windchill',
    format: windchill =>
      Number(windchill) <= 50
        ? formatToFixed(Number(windchill), 1) + '°F'
        : '---'
  },
  {
    name: 'Heat Index',
    value: 'heatindex',
    format: heatindex =>
      `${heatindex >= 60 ? formatToFixed(heatindex, 1) + '°F' : '---'}`
  },
  {
    name: 'Barometer',
    value: 'barometer',
    format: barometer =>
      `${getMillibarsFromSeaLevelPressure(formatToFixed(barometer, 2))} mb`
  },
  {
    name: 'Rain',
    value: 'rain',
    format: rain => `${formatToFixed(rain, 2)} in`
  },
  {
    name: 'Rain Rate',
    value: 'rainRate',
    format: rainRate => `${formatToFixed(rainRate, 2)} in/hr`
  },
  {
    name: 'Inside Temp',
    value: 'inTemp',
    format: temperature => `${formatToFixed(temperature, 1)}°F`
  },
  {
    name: 'Inside Hum',
    value: 'inHumidity',
    format: humidity => `${humidity}%`
  }
];

export default ({ data, width, height }) => {
  const cellRenderer = ({ columnIndex, rowIndex, key, style }) => {
    let displayValue;

    if (rowIndex === 0) {
      displayValue = columns[columnIndex].name;
    } else if (rowIndex > 0 && columns[columnIndex].format) {
      displayValue = columns[columnIndex].format(
        data[rowIndex - 1][columns[columnIndex].value]
      );
    } else {
      displayValue = data[rowIndex - 1][columns[columnIndex].value];
    }

    return (
      <div
        key={key}
        style={style}
        className={`cell${rowIndex % 2 !== 0 ? ' stripe' : ''}`}
      >
        {displayValue}
      </div>
    );
  };

  return (
    <MultiGrid
      cellRenderer={cellRenderer}
      columnCount={14}
      columnWidth={100}
      height={height}
      rowCount={data.length + 1}
      rowHeight={30}
      width={width}
      fixedColumnCount={2}
      fixedRowCount={1}
      classNameTopLeftGrid="header"
      classNameTopRightGrid="header"
      classNameBottomLeftGrid="grid"
      classNameBottomRightGrid="grid"
    />
  );
};
