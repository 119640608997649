import React from 'react';
import { select } from 'd3-selection';
import { arc } from 'd3-shape';

const TWO_PI = 2 * Math.PI;
const MIN_VALUE = -30;
const MAX_VALUE = 120;
// const TEMPERATURE_RANGES = {
//   MINUS_TWENTIES: '#a787bd',
//   MINUS_TEENS: '#c7afdc',
//   MINUS_SINGLE_DIGITS: '#710142',
//   SINGLE_DIGITS: '#851c67',
//   TEENS: '#ad4ea8',
//   TWENTIES: '#b1ddf0',
//   THIRTIES: '#6979be',
//   FOURTIES: '#130d9b',
//   FIFTIES: '#7c706b',
//   SIXTIES: '#d2d538',
//   SEVENTIES: '#d19317',
//   EIGHTIES: '#e41d18',
//   NINETIES: '#850210',
//   ONE_HUNDRED_SINGLE_DIGITS: '#e67ccf',
//   ONE_HUNDRED_TEENS: '#d1cbd4'
// };
const TEMPERATURE_RANGES = {
  MINUS_TWENTIES: {
    COLOR: '#DDDDDD',
    RANGE_MAX: -20
  },
  MINUS_TEENS: {
    COLOR: '#fa03f3',
    RANGE_MIN: -19.9,
    RANGE_MAX: -10.0
  },
  MINUS_SINGLE_DIGITS: {
    COLOR: '#9e02fc',
    RANGE_MIN: -9.9,
    RANGE_MAX: -0.1
  },
  SINGLE_DIGITS: {
    COLOR: '#0200f9',
    RANGE_MIN: 0,
    RANGE_MAX: 9.9
  },
  TEENS: {
    COLOR: '#017ef6',
    RANGE_MIN: 10,
    RANGE_MAX: 19.9
  },
  TWENTIES: {
    COLOR: '#03c9fd',
    RANGE_MIN: 20,
    RANGE_MAX: 29.9
  },
  THIRTIES: {
    COLOR: '#03f1f8',
    RANGE_MIN: 30,
    RANGE_MAX: 39.9
  },
  FOURTIES: {
    COLOR: '#7eff04',
    RANGE_MIN: 40,
    RANGE_MAX: 49.9
  },
  FIFTIES: {
    COLOR: '#f9f505',
    RANGE_MIN: 50,
    RANGE_MAX: 59.9
  },
  SIXTIES: {
    COLOR: '#fbc903',
    RANGE_MIN: 60,
    RANGE_MAX: 69.9
  },
  SEVENTIES: {
    COLOR: '#fe9803',
    RANGE_MIN: 70,
    RANGE_MAX: 79.9
  },
  EIGHTIES: {
    COLOR: '#fe520a',
    RANGE_MIN: 80,
    RANGE_MAX: 89.9
  },
  NINETIES: {
    COLOR: '#cc0003',
    RANGE_MIN: 90,
    RANGE_MAX: 99.9
  },
  ONE_HUNDRED_SINGLE_DIGITS: {
    COLOR: '#a80203',
    RANGE_MIN: 100,
    RANGE_MAX: 109.9
  },
  ONE_HUNDRED_TEENS: {
    COLOR: '#b83337',
    RANGE_MIN: 110
  }
};

class TemperatureRadial extends React.Component {
  state = {
    innerArcInnerRadius: this.props.innerArcInnerRadius,
    innerArcOuterRadius: this.props.innerArcOuterRadius,
    outerArcInnerRadius: this.props.outerArcInnerRadius,
    outerArcOuterRadius: this.props.outerArcOuterRadius
  };

  componentDidMount() {
    this.createArc();
  }

  componentDidUpdate() {
    select(this.node).html(null);

    this.createArc();
  }

  getValuePathColor(value) {
    for (let key in TEMPERATURE_RANGES) {
      if (
        !TEMPERATURE_RANGES[key].RANGE_MIN &&
        value <= TEMPERATURE_RANGES[key].RANGE_MAX
      ) {
        return TEMPERATURE_RANGES[key].COLOR;
      } else if (
        !TEMPERATURE_RANGES[key].RANGE_MAX &&
        value >= TEMPERATURE_RANGES[key].RANGE_MIN
      ) {
        return TEMPERATURE_RANGES[key].COLOR;
      } else if (
        TEMPERATURE_RANGES[key].RANGE_MIN <= value &&
        value <= TEMPERATURE_RANGES[key].RANGE_MAX
      ) {
        return TEMPERATURE_RANGES[key].COLOR;
      }
    }

    return null;
  }

  createArc() {
    const node = this.node;

    const valueInRadians =
      ((this.props.fahrenheitValue - MIN_VALUE) / (MAX_VALUE - MIN_VALUE)) *
      TWO_PI;

    const arcGenerator = arc();

    const valuePathData = arcGenerator({
      startAngle: 0,
      endAngle: valueInRadians < 0 ? 0 : valueInRadians,
      innerRadius: this.state.innerArcInnerRadius,
      outerRadius: this.state.innerArcOuterRadius
    });

    const valuePathColor = this.getValuePathColor(this.props.fahrenheitValue);

    const minusTwenties = arcGenerator({
      startAngle: 0,
      endAngle: (10 / 150) * TWO_PI,
      innerRadius: this.state.outerArcInnerRadius,
      outerRadius: this.state.outerArcOuterRadius,
      padAngle: 0.02
    });

    const minusTeens = arcGenerator({
      startAngle: (10 / 150) * TWO_PI,
      endAngle: (20 / 150) * TWO_PI,
      innerRadius: this.state.outerArcInnerRadius,
      outerRadius: this.state.outerArcOuterRadius,
      padAngle: 0.02
    });

    const minusSingleDigits = arcGenerator({
      startAngle: (20 / 150) * TWO_PI,
      endAngle: (30 / 150) * TWO_PI,
      innerRadius: this.state.outerArcInnerRadius,
      outerRadius: this.state.outerArcOuterRadius,
      padAngle: 0.02
    });

    const singleDigits = arcGenerator({
      startAngle: (30 / 150) * TWO_PI,
      endAngle: (40 / 150) * TWO_PI,
      innerRadius: this.state.outerArcInnerRadius,
      outerRadius: this.state.outerArcOuterRadius,
      padAngle: 0.02
    });

    const teens = arcGenerator({
      startAngle: (40 / 150) * TWO_PI,
      endAngle: (50 / 150) * TWO_PI,
      innerRadius: this.state.outerArcInnerRadius,
      outerRadius: this.state.outerArcOuterRadius,
      padAngle: 0.02
    });

    const twenties = arcGenerator({
      startAngle: (50 / 150) * TWO_PI,
      endAngle: (60 / 150) * TWO_PI,
      innerRadius: this.state.outerArcInnerRadius,
      outerRadius: this.state.outerArcOuterRadius,
      padAngle: 0.02
    });

    const thirties = arcGenerator({
      startAngle: (60 / 150) * TWO_PI,
      endAngle: (70 / 150) * TWO_PI,
      innerRadius: this.state.outerArcInnerRadius,
      outerRadius: this.state.outerArcOuterRadius,
      padAngle: 0.02
    });

    const fourties = arcGenerator({
      startAngle: (70 / 150) * TWO_PI,
      endAngle: (80 / 150) * TWO_PI,
      innerRadius: this.state.outerArcInnerRadius,
      outerRadius: this.state.outerArcOuterRadius,
      padAngle: 0.02
    });

    const fifties = arcGenerator({
      startAngle: (80 / 150) * TWO_PI,
      endAngle: (90 / 150) * TWO_PI,
      innerRadius: this.state.outerArcInnerRadius,
      outerRadius: this.state.outerArcOuterRadius,
      padAngle: 0.02
    });

    const sixties = arcGenerator({
      startAngle: (90 / 150) * TWO_PI,
      endAngle: (100 / 150) * TWO_PI,
      innerRadius: this.state.outerArcInnerRadius,
      outerRadius: this.state.outerArcOuterRadius,
      padAngle: 0.02
    });

    const seventies = arcGenerator({
      startAngle: (100 / 150) * TWO_PI,
      endAngle: (110 / 150) * TWO_PI,
      innerRadius: this.state.outerArcInnerRadius,
      outerRadius: this.state.outerArcOuterRadius,
      padAngle: 0.02
    });

    const eighties = arcGenerator({
      startAngle: (110 / 150) * TWO_PI,
      endAngle: (120 / 150) * TWO_PI,
      innerRadius: this.state.outerArcInnerRadius,
      outerRadius: this.state.outerArcOuterRadius,
      padAngle: 0.02
    });

    const nineties = arcGenerator({
      startAngle: (120 / 150) * TWO_PI,
      endAngle: (130 / 150) * TWO_PI,
      innerRadius: this.state.outerArcInnerRadius,
      outerRadius: this.state.outerArcOuterRadius,
      padAngle: 0.02
    });

    const oneHundredSingleDigits = arcGenerator({
      startAngle: (130 / 150) * TWO_PI,
      endAngle: (140 / 150) * TWO_PI,
      innerRadius: this.state.outerArcInnerRadius,
      outerRadius: this.state.outerArcOuterRadius,
      padAngle: 0.02
    });

    const oneHundredTeens = arcGenerator({
      startAngle: (140 / 150) * TWO_PI,
      endAngle: (150 / 150) * TWO_PI,
      innerRadius: this.state.outerArcInnerRadius,
      outerRadius: this.state.outerArcOuterRadius,
      padAngle: 0.02
    });

    const svgGroup = select(node)
      .append('g')
      .attr(
        'transform',
        `translate(${this.props.size / 2}, ${this.props.size / 2})`
      );

    svgGroup
      .append('path')
      .attr('d', valuePathData)
      .attr('fill', valuePathColor)
      .attr('stroke', valuePathColor);

    svgGroup
      .append('path')
      .attr('d', minusTwenties)
      .attr('fill', TEMPERATURE_RANGES.MINUS_TWENTIES.COLOR)
      .attr('stroke', TEMPERATURE_RANGES.MINUS_TWENTIES.COLOR);

    svgGroup
      .append('path')
      .attr('d', minusTeens)
      .attr('fill', TEMPERATURE_RANGES.MINUS_TEENS.COLOR)
      .attr('stroke', TEMPERATURE_RANGES.MINUS_TEENS.COLOR);

    svgGroup
      .append('path')
      .attr('d', minusSingleDigits)
      .attr('fill', TEMPERATURE_RANGES.MINUS_SINGLE_DIGITS.COLOR)
      .attr('stroke', TEMPERATURE_RANGES.MINUS_SINGLE_DIGITS.COLOR);

    svgGroup
      .append('path')
      .attr('d', singleDigits)
      .attr('fill', TEMPERATURE_RANGES.SINGLE_DIGITS.COLOR)
      .attr('stroke', TEMPERATURE_RANGES.SINGLE_DIGITS.COLOR);

    svgGroup
      .append('path')
      .attr('d', teens)
      .attr('fill', TEMPERATURE_RANGES.TEENS.COLOR)
      .attr('stroke', TEMPERATURE_RANGES.TEENS.COLOR);

    svgGroup
      .append('path')
      .attr('d', twenties)
      .attr('fill', TEMPERATURE_RANGES.TWENTIES.COLOR)
      .attr('stroke', TEMPERATURE_RANGES.TWENTIES.COLOR);

    svgGroup
      .append('path')
      .attr('d', thirties)
      .attr('fill', TEMPERATURE_RANGES.THIRTIES.COLOR)
      .attr('stroke', TEMPERATURE_RANGES.THIRTIES.COLOR);

    svgGroup
      .append('path')
      .attr('d', fourties)
      .attr('fill', TEMPERATURE_RANGES.FOURTIES.COLOR)
      .attr('stroke', TEMPERATURE_RANGES.FOURTIES.COLOR);

    svgGroup
      .append('path')
      .attr('d', fifties)
      .attr('fill', TEMPERATURE_RANGES.FIFTIES.COLOR)
      .attr('stroke', TEMPERATURE_RANGES.FIFTIES.COLOR);

    svgGroup
      .append('path')
      .attr('d', sixties)
      .attr('fill', TEMPERATURE_RANGES.SIXTIES.COLOR)
      .attr('stroke', TEMPERATURE_RANGES.SIXTIES.COLOR);

    svgGroup
      .append('path')
      .attr('d', seventies)
      .attr('fill', TEMPERATURE_RANGES.SEVENTIES.COLOR)
      .attr('stroke', TEMPERATURE_RANGES.SEVENTIES.COLOR);

    svgGroup
      .append('path')
      .attr('d', eighties)
      .attr('fill', TEMPERATURE_RANGES.EIGHTIES.COLOR)
      .attr('stroke', TEMPERATURE_RANGES.EIGHTIES.COLOR);

    svgGroup
      .append('path')
      .attr('d', nineties)
      .attr('fill', TEMPERATURE_RANGES.NINETIES.COLOR)
      .attr('stroke', TEMPERATURE_RANGES.NINETIES.COLOR);

    svgGroup
      .append('path')
      .attr('d', oneHundredSingleDigits)
      .attr('fill', TEMPERATURE_RANGES.ONE_HUNDRED_SINGLE_DIGITS.COLOR)
      .attr('stroke', TEMPERATURE_RANGES.ONE_HUNDRED_SINGLE_DIGITS.COLOR);

    svgGroup
      .append('path')
      .attr('d', oneHundredTeens)
      .attr('fill', TEMPERATURE_RANGES.ONE_HUNDRED_TEENS.COLOR)
      .attr('stroke', TEMPERATURE_RANGES.ONE_HUNDRED_TEENS.COLOR);

    svgGroup
      .append('text')
      .attr('dominant-baseline', 'central')
      .attr('transform', `translate(0, ${this.props.mainTextTranslation})`)
      .attr('text-anchor', 'middle')
      .attr('font-size', this.props.celsiusFontSize)
      .attr('fill', '#aeb3b8')
      .attr('stroke', '#aeb3b8')
      .text(this.props.mainText);

    svgGroup
      .append('text')
      .attr('dominant-baseline', 'central')
      .attr('text-anchor', 'middle')
      .attr('font-size', this.props.fahrenheitFontSize)
      .attr('fill', '#aeb3b8')
      .attr('stroke', '#aeb3b8')
      .text(this.props.fahrenheitValue + '°F');

    svgGroup
      .append('text')
      .attr('dominant-baseline', 'central')
      .attr('transform', `translate(0, ${this.props.celsiusTranslation})`)
      .attr('text-anchor', 'middle')
      .attr('font-size', this.props.celsiusFontSize)
      .attr('fill', '#aeb3b8')
      .attr('stroke', '#aeb3b8')
      .text(this.props.celsiusValue + '°C');
  }

  render() {
    return (
      <svg
        ref={node => (this.node = node)}
        width={this.props.size}
        height={this.props.size}
      />
    );
  }
}

export default TemperatureRadial;
