import React from 'react';
import { DateTime } from 'luxon';
import Loading from '../Common/Loading';
import { formatToFixed } from '../../helpers/format';
import { getBarometerTrendInfo, getStormTotal } from '../../helpers/weather';

export function withCurrentData(WrappedComponent) {
  return class extends React.Component {
    state = {
      loading: true
    };

    componentDidMount() {
      const currentWeather = fetch(process.env.REACT_APP_CURRENT_WEATHER_URL);
      const highsAndLows = fetch(process.env.REACT_APP_HIGHS_LOWS_URL);
      const yearlyRainTotals = fetch(process.env.REACT_APP_YEARLY_RAIN_TOTALS);
      const stormTotalData = fetch(process.env.REACT_APP_STORM_TOTAL_DATA);

      Promise.all([
        currentWeather,
        highsAndLows,
        yearlyRainTotals,
        stormTotalData
      ]).then(responses => {
        Promise.all(responses.map(response => response.json())).then(json => {
          this.setState({ currentWeather: json[0][0] });
          this.setState({ highsAndLows: json[1][0] });
          this.setState({ yearlyRainTotals: json[2] });
          this.setState({ stormTotalData: json[3] });

          this.setState(() => {
            const stormTotal = getStormTotal(json[3]);

            return {
              stormTotal:
                stormTotal === 'NA'
                  ? 'NA'
                  : `${formatToFixed(stormTotal, 2)} in`
            };
          });

          const oneHourAgo =
            DateTime.fromMillis(json[0][0].dateTime * 1000).minus({
              minutes: 55
            }).ts / 1000;

          const threeHoursAgo =
            DateTime.fromMillis(json[0][0].dateTime * 1000).minus({
              hours: 3
            }).ts / 1000;

          const twentyFourHoursAgo =
            DateTime.fromMillis(json[0][0].dateTime * 1000).minus({
              hours: 24
            }).ts / 1000;

          Promise.all([
            fetch(
              `${process.env.REACT_APP_WEATHER_AT_TIME}&dateTime=${threeHoursAgo}`
            ),
            fetch(
              `${process.env.REACT_APP_WEATHER_AT_TIME}&dateTime=${twentyFourHoursAgo}`
            ),
            fetch(
              `${process.env.REACT_APP_ARCHIVE_SINCE_TIME}&dateTime=${oneHourAgo}`
            )
          ]).then(responses => {
            Promise.all(responses.map(response => response.json())).then(
              json => {
                this.setState(state => {
                  return getBarometerTrendInfo(
                    json[0][0].barometer,
                    state.currentWeather.barometer
                  );
                });

                this.setState(state => {
                  return {
                    twentyFourHourTempDifference:
                      state.currentWeather.outTemp - json[1][0].outTemp
                  };
                });

                this.setState(state => {
                  return {
                    lastHourArchiveData: json[2]
                  };
                });

                this.setState({ loading: false });
              }
            );
          });
        });
      });
    }

    render() {
      if (!this.state.loading) {
        return <WrappedComponent data={this.state} {...this.props} />;
      }

      return (
        <div className="loading">
          <Loading />
        </div>
      );
    }
  };
}
