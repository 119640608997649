import React, { Component } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import DatePicker from 'material-ui-pickers/DatePicker';
import { DateTime } from 'luxon';
import Loading from '../Common/Loading';
import PresetButton from '../Common/PresetButton';
import DataArchiveGrid from './DataArchiveGrid';
import './Reports.css';

const reportTypes = {
  dataArchive: 'DATA_ARCHIVE',
  monthly: 'MONTHLY',
  annual: 'ANNUAL'
};

const stateDefaults = {
  beginDate: DateTime.local().minus({ days: 1 }),
  endDate: DateTime.local().minus({ days: 1 }),
  dateRangeError: null,
  loading: false,
  data: null
};

export default class Reports extends Component {
  state = {
    reportType: reportTypes.dataArchive,
    contentRef: React.createRef(),
    contentWidth: null,
    contentHeight: null,
    ...stateDefaults
  };

  ro = null;

  componentDidMount() {
    this.ro = new ResizeObserver(this.onResize);

    this.ro.observe(this.state.contentRef.current);

    this.fetchDataArchiveReport(this.state.beginDate, this.state.endDate);
  }

  componentWillUnmount() {
    this.ro.unobserve(this.state.contentRef.current);
  }

  onResize = (entries, observer) => {
    this.setState({
      contentWidth:
        entries[0].contentRect.width > 1420
          ? 1420
          : entries[0].contentRect.width,
      contentHeight: entries[0].contentRect.height
    });
  };

  setReportType(type) {
    this.setState({ reportType: type });
  }

  fetchDataArchiveReport(begin, end) {
    this.setState({ loading: true, data: null });

    fetch(
      `${process.env.REACT_APP_ARCHIVE_WITHIN_RANGE}&begin=${begin
        .startOf('day')
        .toMillis() / 1000}&end=${end.endOf('day').toMillis() /
        1000}&sortDirection=ASC`
    ).then(response => {
      response.json().then(json => {
        this.setState({ loading: false, data: json });
      });
    });
  }

  validateInput() {
    switch (this.state.reportType) {
      case reportTypes.dataArchive:
        const daysDifference = this.state.endDate.diff(
          this.state.beginDate,
          'days'
        ).days;

        if (daysDifference > 8) {
          this.setState({
            dateRangeError: 'The date range must be a week or less.',
            data: stateDefaults.data
          });
        } else if (daysDifference < 0) {
          this.setState({
            dateRangeError: 'The begin date must be before the end date.',
            data: stateDefaults.data
          });
        } else {
          this.setState({ dateRangeError: null });

          this.fetchDataArchiveReport(this.state.beginDate, this.state.endDate);
        }
        break;

      default:
        break;
    }
  }

  handleBeginDateChange(date) {
    this.setState({ beginDate: date }, this.validateInput);
  }

  handleEndDateChange(date) {
    this.setState({ endDate: date }, this.validateInput);
  }

  render() {
    return (
      <div id="Reports">
        <div id="report-types">
          <PresetButton
            className="button"
            selected={this.state.reportType === reportTypes.dataArchive}
            onClick={() => this.setReportType(reportTypes.dataArchive)}
          >
            Data Archive
          </PresetButton>
          <PresetButton
            className="button"
            selected={this.state.reportType === reportTypes.monthly}
            onClick={() => this.setReportType(reportTypes.monthly)}
          >
            Monthly
          </PresetButton>
          <PresetButton
            className="button"
            selected={this.state.reportType === reportTypes.annual}
            onClick={() => this.setReportType(reportTypes.annual)}
          >
            Annual
          </PresetButton>
        </div>
        <div id="date-selection">
          {this.state.reportType === reportTypes.dataArchive && (
            <div id="date-range">
              <div className="title">Date Range</div>
              <div className="range">
                <DatePicker
                  allowKeyboardControl={false}
                  className="begin"
                  leftArrowIcon="<"
                  rightArrowIcon=">"
                  autoOk={true}
                  minDate={DateTime.local(2018, 1, 1).startOf('year')}
                  maxDate={DateTime.local()}
                  format={'LL/dd/yyyy'}
                  value={this.state.beginDate}
                  onChange={date => this.handleBeginDateChange(date)}
                />
                <DatePicker
                  allowKeyboardControl={false}
                  className="end"
                  leftArrowIcon="<"
                  rightArrowIcon=">"
                  autoOk={true}
                  minDate={DateTime.local(2018, 1, 1).startOf('year')}
                  maxDate={DateTime.local()}
                  format={'LL/dd/yyyy'}
                  value={this.state.endDate}
                  onChange={date => this.handleEndDateChange(date)}
                />
              </div>
              {this.state.dateRangeError && (
                <div className="error">{this.state.dateRangeError}</div>
              )}
            </div>
          )}
        </div>
        <div id="content" ref={this.state.contentRef}>
          {this.state.loading && <Loading />}
          {this.state.reportType === reportTypes.dataArchive &&
            !this.state.loading &&
            this.state.data && (
              <DataArchiveGrid
                id="data-archive-grid"
                data={this.state.data}
                width={this.state.contentWidth}
                height={this.state.contentHeight}
              />
            )}
          {(this.state.reportType === reportTypes.monthly ||
            this.state.reportType === reportTypes.annual) && (
            <div id="coming-soon">Coming Soon!</div>
          )}
        </div>
      </div>
    );
  }
}
