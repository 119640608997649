import React from 'react';
import { DateTime } from 'luxon';
import { formatToFixed, formatTime } from '../../helpers/format';
import {
  getMillibarsFromSeaLevelPressure,
  toCelsius
} from '../../helpers/weather';
import HighsAndLowsHeader from './HighsAndLowsHeader';
import TemperatureRadial from '../TemperatureRadial';

function HighsAndLows(props) {
  return (
    <div className="highs-lows">
      <HighsAndLowsHeader
        title="Daily Highs and Lows"
        name="Castle Valley - Castle Pines, CO"
        date={DateTime.local().toLocaleString(DateTime.DATE_SHORT)}
      />
      <div className="temperatures">
        <TemperatureRadial
          fahrenheitValue={formatToFixed(props.highsAndLows.outTempMax, 1)}
          celsiusValue={formatToFixed(
            toCelsius(props.highsAndLows.outTempMax),
            1
          )}
          size={220}
          innerArcInnerRadius={92}
          innerArcOuterRadius={99}
          outerArcInnerRadius={104}
          outerArcOuterRadius={107}
          mainText="High"
          mainTextTranslation="-35"
          fahrenheitFontSize="25pt"
          celsiusFontSize="13pt"
          celsiusTranslation="35"
        />
        <TemperatureRadial
          fahrenheitValue={formatToFixed(props.highsAndLows.outTempMin, 1)}
          celsiusValue={formatToFixed(
            toCelsius(props.highsAndLows.outTempMin),
            1
          )}
          size={220}
          innerArcInnerRadius={92}
          innerArcOuterRadius={99}
          outerArcInnerRadius={104}
          outerArcOuterRadius={107}
          mainText="Low"
          mainTextTranslation="-35"
          fahrenheitFontSize="25pt"
          celsiusFontSize="13pt"
          celsiusTranslation="35"
        />
      </div>
      <div className="data">
        <div className="header">
          <div />
          <div className="high">High</div>
          <div className="low">Low</div>
        </div>
        <div className="row">
          <div className="name">Temperature</div>
          <div className="high">
            <div>{formatToFixed(props.highsAndLows.outTempMax, 1)}°F</div>
            <div className="time">
              {formatTime(props.highsAndLows.outTempMaxTime)}
            </div>
          </div>
          <div className="low">
            <div>{formatToFixed(props.highsAndLows.outTempMin, 1)}°F</div>
            <div className="time">
              {formatTime(props.highsAndLows.outTempMinTime)}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="name">Humidity</div>
          <div className="high">
            <div>{props.highsAndLows.outHumidityMax}%</div>
            <div className="time">
              {formatTime(props.highsAndLows.outHumidityMaxTime)}
            </div>
          </div>
          <div className="low">
            <div>{props.highsAndLows.outHumidityMin}%</div>
            <div className="time">
              {formatTime(props.highsAndLows.outHumidityMinTime)}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="name">Dewpoint</div>
          <div className="high">
            <div>{formatToFixed(props.highsAndLows.dewPointMax, 1)}°F</div>
            <div className="time">
              {formatTime(props.highsAndLows.dewPointMaxTime)}
            </div>
          </div>
          <div className="low">
            <div>{formatToFixed(props.highsAndLows.dewPointMin, 1)}°F</div>
            <div className="time">
              {formatTime(props.highsAndLows.dewPointMinTime)}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="name">Wind Speed</div>
          <div className="high">
            <div>{formatToFixed(props.highsAndLows.windMax, 1)} mph</div>
            <div className="time">
              {formatTime(props.highsAndLows.windMaxTime)}
            </div>
          </div>
          <div className="low">---</div>
        </div>
        {/* <div className="row">
          <div className="name">Wind Gust</div>
          <div className="high">
            <div>{formatToFixed(props.highsAndLows.windGustMax, 1)} mph</div>
            <div className="time">
              {formatTime(props.highsAndLows.windGustMaxTime)}
            </div>
          </div>
          <div className="low">---</div>
        </div> */}
        <div className="row">
          <div className="name">Barometer</div>
          <div className="high">
            <div>{formatToFixed(props.highsAndLows.barometerMax, 2)} in</div>
            <div>
              {getMillibarsFromSeaLevelPressure(
                props.highsAndLows.barometerMax
              )}{' '}
              mb
            </div>
            <div className="time">
              {formatTime(props.highsAndLows.barometerMaxTime)}
            </div>
          </div>
          <div className="low">
            <div>{formatToFixed(props.highsAndLows.barometerMin, 2)} in</div>
            <div>
              {getMillibarsFromSeaLevelPressure(
                props.highsAndLows.barometerMin
              )}{' '}
              mb
            </div>
            <div className="time">
              {formatTime(props.highsAndLows.barometerMinTime)}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="name">Wind Chill</div>
          <div className="high">---</div>
          <div className="low">
            <div>
              {props.highsAndLows.windChillMin <= 50
                ? formatToFixed(props.highsAndLows.windChillMin, 1) + '°F'
                : 'NA'}
            </div>
            <div className="time">
              {props.highsAndLows.windChillMin <= 50
                ? formatTime(props.highsAndLows.windChillMinTime)
                : ''}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="name">Heat Index</div>
          <div className="high">
            <div>
              {props.highsAndLows.heatIndexMax >= 60
                ? formatToFixed(props.highsAndLows.heatIndexMax, 1) + '°F'
                : 'NA'}
            </div>
            <div className="time">
              {props.highsAndLows.heatIndexMax >= 60
                ? formatTime(props.highsAndLows.heatIndexMaxTime)
                : ''}
            </div>
          </div>
          <div className="low">---</div>
        </div>
        <div className="row">
          <div className="name">Rain Rate</div>
          <div className="high">
            <div>
              {props.highsAndLows.rainRateMax === '0'
                ? 'NA'
                : `${formatToFixed(props.highsAndLows.rainRateMax, 2)} in/hr`}
            </div>
            <div className="time">
              {props.highsAndLows.rainRateMax === '0'
                ? ''
                : formatTime(props.highsAndLows.rainRateMaxTime)}
            </div>
          </div>
          <div className="low">---</div>
        </div>
      </div>
    </div>
  );
}

export default HighsAndLows;
