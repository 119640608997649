import React from 'react';
import { NavLink } from 'react-router-dom';
import './NavBar.css';

function NavBar(props) {
  return (
    <nav className="NavBar">
      <NavLink to="/current">
        <div className="button active">CURRENT</div>
      </NavLink>
      <NavLink to="/forecast">
        <div className="button">FORECAST</div>
      </NavLink>
      <NavLink to="/history">
        <div className="button">HISTORY</div>
      </NavLink>
      <NavLink to="/reports">
        <div className="button">REPORTS</div>
      </NavLink>
    </nav>
  );
}

export default NavBar;
