import React, { Component } from 'react';
import './Loading.css';

export default class Loading extends Component {
  state = {
    display: false
  };

  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.setState({ display: true });
    }, 700);
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  render() {
    return (
      this.state.display && (
        <div style={{ color: '#2196f3' }} className="la-ball-atom la-3x">
          <div />
          <div />
          <div />
          <div />
        </div>
      )
    );
  }
}
