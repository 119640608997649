import React from 'react';
import Loading from '../Common/Loading';

export function withForecastData(WrappedComponent) {
  return class extends React.Component {
    state = {
      loading: true,
      error: false
    };

    componentDidMount() {
      fetch(process.env.REACT_APP_FORECAST_WEATHER_URL).then((response) => {
        if (response.status !== 200) {
          this.setState({ error: true });
        } else {
          response.json().then(forecast => {
            this.setState({ forecast });
            this.setState({ loading: false });
          });
        }
      });
    }

    render() {
      if (this.state.error) {
        return (
          <div className="error">
            <div>An error occurred when retrieving data from the NWS.</div>
          </div>
        );
      } else if (this.state.loading) {
        return (
          <div className="loading">
            <Loading />
          </div>
        );
      }

      return <WrappedComponent data={this.state} {...this.props} />;
    }
  };
}
