import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const HighsAndLowsHeaderContainer = styled.div`
  grid-column: 1 / -1;
  grid-row: 1 / 2;

  text-align: center;
`;
HighsAndLowsHeaderContainer.displayName = 'HighsAndLowsHeaderContainer';

const Title = styled.div`
  font-size: 1.1rem;
`;
Title.displayName = 'Title';

const Location = styled.div`
  font-size: 0.85rem;
  font-weight: 300;
`;
Location.displayName = 'Location';

const Date = styled.div`
  margin-top: 10px;

  font-size: 0.85rem;
`;
Date.displayName = 'Date';

const HighsAndLowsHeader = props => (
  <HighsAndLowsHeaderContainer>
    <Title>{props.title}</Title>
    <Location>{props.name}</Location>
    <Date>{props.date}</Date>
  </HighsAndLowsHeaderContainer>
);

HighsAndLowsHeader.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired
};

export default HighsAndLowsHeader;
