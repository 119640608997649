import React from 'react';
import './WeatherStationDataItem.css';

function WeatherStationDataItem(props) {
  return (
    <div className="data-item">
      <div className="names">
        <div className="name-one">{props.nameOne}</div>
        <div className="name-two">{props.nameTwo}</div>
      </div>
      <div className="values">
        <div className="value-one">{props.valueOne}</div>
        <div className="value-two">{props.valueTwo}</div>
      </div>
    </div>
  );
}

export default WeatherStationDataItem;
