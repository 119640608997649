import { DateTime } from 'luxon';

export function formatToFixed(value, decimalPlaces) {
  return Number(value).toFixed(decimalPlaces);
}

export function getUnixTimestamp(date) {
  return Math.round(date.toMillis() / 1000.0);
}

export function formatDate(dateTime) {
  return DateTime.fromMillis(dateTime * 1000, {
    zone: process.env.REACT_APP_DISPLAY_TIME_ZONE
  }).toLocaleString(DateTime.DATE_SHORT);
}

export function formatTime(dateTime) {
  return DateTime.fromMillis(dateTime * 1000, {
    zone: process.env.REACT_APP_DISPLAY_TIME_ZONE
  }).toLocaleString(DateTime.TIME_SIMPLE);
}

export function formatDateAndTime(dateTime) {
  return DateTime.fromMillis(dateTime * 1000, {
    zone: process.env.REACT_APP_DISPLAY_TIME_ZONE
  }).toLocaleString(DateTime.DATETIME_SHORT);
}

export function formatTemperatureChange(temperature) {
  let change = `${formatToFixed(temperature, 1)}°F`;

  if (temperature > 0) {
    change = `+${change}`;
  }

  return change;
}
