import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StationInfoContainer = styled.div`
  grid-column: 1 / -1;
  grid-row: 1 / 2;

  margin: 20px 0;

  text-align: center;
`;

const StationName = styled.div`
  font-size: 1.1rem;
`;
StationName.displayName = 'StationName';

const StationLocation = styled.div`
  font-size: 0.85rem;
  font-weight: 300;

  span {
    margin: 0 10px;
  }
`;
StationLocation.displayName = 'StationLocation';

const LastUpdate = styled.div`
  margin-top: 10px;

  font-size: 0.85rem;
`;
LastUpdate.displayName = 'LastUpdate';

const StationInfo = props => (
  <StationInfoContainer>
    <StationName>{props.name}</StationName>
    <StationLocation>
      <span>Lat: {props.latitude}</span>
      <span>Lon: {props.longitude}</span>
      <span>Elev: {props.elevation}</span>
    </StationLocation>
    {props.lastUpdate && (
      <LastUpdate>Last Update: {props.lastUpdate}</LastUpdate>
    )}
  </StationInfoContainer>
);

StationInfo.propTypes = {
  name: PropTypes.string.isRequired,
  latitude: PropTypes.string.isRequired,
  longitude: PropTypes.string.isRequired,
  elevation: PropTypes.string.isRequired
};

export default StationInfo;
