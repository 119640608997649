import React from 'react';
import './Current.css';
import { withCurrentData } from './withCurrentData';
import WeatherStation from '../Common/WeatherStation';
import HighsAndLows from './HighsAndLows';

class Current extends React.Component {
  render() {
    return (
      <div id="Current">
        <WeatherStation
          weather={this.props.data.currentWeather}
          todaysRain={this.props.data.yearlyRainTotals[0]}
          lastFifteenMinutesRain={this.props.data.lastHourArchiveData
            .slice(0, 3)
            .reduce((sum, record) => sum + +record.rain, 0)}
          lastHoursRain={this.props.data.lastHourArchiveData.reduce(
            (sum, record) => sum + +record.rain,
            0
          )}
          stormTotal={this.props.data.stormTotal}
          twentyFourHourTempDifference={
            this.props.data.twentyFourHourTempDifference
          }
          barometerTrend={this.props.data.barometerTrend}
        />
        <HighsAndLows highsAndLows={this.props.data.highsAndLows} />
      </div>
    );
  }
}

export default withCurrentData(Current);
