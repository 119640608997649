import React from 'react';
import {
  formatDateAndTime,
  formatToFixed,
  formatTemperatureChange
} from '../../helpers/format';
import {
  getMillibarsFromSeaLevelPressure,
  getWindInfo,
  toCelsius
} from '../../helpers/weather';
import StationInfo from './StationInfo';
import TemperatureRadial from '../TemperatureRadial';
import WeatherStationDataItem from './WeatherStationDataItem';

function WeatherStation(props) {
  return (
    <div className="weather-station">
      <StationInfo
        name="Castle Valley - Castle Pines, CO"
        latitude="39.47°N"
        longitude="-104.88°W"
        elevation="6394 ft"
        lastUpdate={formatDateAndTime(props.weather.dateTime)}
      />
      <div className="data">
        <div className="temperature">
          <TemperatureRadial
            fahrenheitValue={formatToFixed(props.weather.outTemp, 1)}
            celsiusValue={formatToFixed(toCelsius(props.weather.outTemp), 1)}
            size={350}
            innerArcInnerRadius={142}
            innerArcOuterRadius={149}
            outerArcInnerRadius={154}
            outerArcOuterRadius={157}
            mainText="Currently"
            mainTextTranslation="-45"
            fahrenheitFontSize="37pt"
            celsiusFontSize="17pt"
            celsiusTranslation="45"
          />
        </div>
        <div className="additional-data">
          <WeatherStationDataItem
            nameOne="Temperature"
            valueOne={`${formatToFixed(props.weather.outTemp, 1)}°F`}
          />
          <WeatherStationDataItem
            nameOne="Humidity"
            valueOne={`${props.weather.outHumidity}%`}
          />
          <WeatherStationDataItem
            nameOne="Dewpoint"
            valueOne={`${formatToFixed(props.weather.dewpoint, 1)}°F`}
          />
          <WeatherStationDataItem
            nameOne="Wind"
            valueOne={getWindInfo(
              props.weather.windSpeed,
              props.weather.windDir
            )}
          />
          <WeatherStationDataItem
            nameOne="Wind Gust"
            valueOne={`${formatToFixed(props.weather.windGust, 1)} mph`}
          />
          <WeatherStationDataItem
            nameOne="Barometer"
            valueOne={`${formatToFixed(props.weather.barometer, 2)} in & ${
              props.barometerTrend
            }`}
            valueTwo={`${getMillibarsFromSeaLevelPressure(
              formatToFixed(props.weather.barometer, 2)
            )} mb`}
          />
          <WeatherStationDataItem
            nameOne="Wind Chill"
            valueOne={`${
              Number(props.weather.windchill) <= 50
                ? formatToFixed(Number(props.weather.windchill), 1) + '°F'
                : 'NA'
            }`}
          />
          <WeatherStationDataItem
            nameOne="Heat Index"
            valueOne={`${
              props.weather.heatindex >= 60
                ? formatToFixed(props.weather.heatindex, 1) + '°F'
                : 'NA'
            }`}
          />
          <WeatherStationDataItem
            nameOne="24-Hour Temp Change"
            valueOne={formatTemperatureChange(
              props.twentyFourHourTempDifference
            )}
          />
          <WeatherStationDataItem
            nameOne="Precipitation"
            nameTwo="(last 5 / 15 / 60 min)"
            valueOne={`${formatToFixed(
              props.weather.rain,
              2
            )} / ${formatToFixed(
              props.lastFifteenMinutesRain,
              2
            )} / ${formatToFixed(props.lastHoursRain, 2)} in`}
          />
          <WeatherStationDataItem
            nameOne="Rain Rate"
            valueOne={`${formatToFixed(props.weather.rainRate, 2)} in/hr`}
          />
          <WeatherStationDataItem
            nameOne="Storm Total"
            valueOne={props.stormTotal}
          />
          <WeatherStationDataItem
            nameOne="Today's Precip"
            valueOne={`${formatToFixed(props.todaysRain.sum, 2)} in`}
          />
          {/* <WeatherStationDataItem
            nameOne="Monthly Rain"
            valueOne={getMonthlyRain(props.rainTotals)}
          /> */}
          {/* <WeatherStationDataItem
            nameOne="Yearly Rain"
            valueOne={getYearlyRain(props.rainTotals)}
          /> */}
          {/* <WeatherStationDataItem
                nameOne="Altimeter"
                valueOne={props.weather.altimeter}
              /> */}
          {/* <WeatherStationDataItem
                nameOne="Inside Temperature"
                valueOne={props.weather.inTemp}
              /> */}
          {/* <WeatherStationDataItem
                nameOne="Inside Humidity"
                valueOne={props.weather.inHumidity}
              /> */}
        </div>
      </div>
    </div>
  );
}

export default WeatherStation;
