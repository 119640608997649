import React from 'react';
import './Forecast.css';
import { withForecastData } from './withForecastData';

class Forecast extends React.Component {
    render() {
        const { forecast } = this.props.data;

        return (
            <div id="Forecast">
                <div id="ForecastShort">
                    {forecast.properties.periods.map(period => {
                        const {
                            icon,
                            isDaytime,
                            name,
                            shortForecast,
                            temperature,
                            temperatureUnit,
                        } = period;

                        return <div className='ShortDayCard' key={name}>
                            <div className='Name'>{name}</div>
                            <img src={icon} alt={shortForecast} />
                            <div className='shortForecast'>{shortForecast}</div>
                            <div className={`temperature ${isDaytime ? 'daytime' : 'nighttime'}`}>{temperature} &deg;{temperatureUnit}</div>
                        </div>;
                    })}
                </div >
                <table id="ForecastDetailed">
                    <tbody>
                        {forecast.properties.periods.map(period => {
                            const { detailedForecast, name } = period;

                            return <tr className="Item" key={name}>
                                <td className='Name'>
                                    {name}
                                </td>
                                <td className='DetailedForecast'>
                                    {detailedForecast}
                                </td>
                            </tr>;
                        })}
                    </tbody>
                </table >
            </div>
        );
    }
}

export default withForecastData(Forecast);
