import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import MuiPickersUtilsProvider from 'material-ui-pickers/utils/MuiPickersUtilsProvider';
import LuxonUtils from 'material-ui-pickers/utils/luxon-utils';
import { Settings } from 'luxon';
import './App.css';
import NavBar from './components/NavBar';
import Current from './components/Current/Current';
import Forecast from './components/Forecast/Forecast';
import History from './components/History/History';
import Reports from './components/Reports/Reports';

Settings.defaultZoneName = process.env.REACT_APP_DEFAULT_LUXON_ZONE_NAME;

class App extends Component {
  render() {
    const theme = createMuiTheme({ palette: { type: 'dark' } });

    return (
      <Router>
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={LuxonUtils}>
            <div className="App">
              <NavBar />
              <Switch>
                <Route exact path="/current" component={Current} />
                <Route exact path="/forecast" component={Forecast} />
                <Route exact path="/history" component={History} />
                <Route exact path="/reports" component={Reports} />
                <Redirect exact from="/" to="/current" />
              </Switch>
            </div>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </Router>
    );
  }
}

export default App;
