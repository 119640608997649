import { DateTime } from 'luxon';
import { formatToFixed } from './format';

const WIND_DIRECTIONS = [
  'N',
  'NNE',
  'NE',
  'ENE',
  'E',
  'ESE',
  'SE',
  'SSE',
  'S',
  'SSW',
  'SW',
  'WSW',
  'W',
  'WNW',
  'NW',
  'NNW',
  'N'
];

export function getStormTotal(data) {
  // Get rid of the first value since it is not a part of the storm total
  data.shift();

  let storm = false;

  const today = Number(DateTime.local().startOf('day').ts / 1000);

  const stormTotal = data.reduce((sum, day) => {
    const dayTotal = Number(day.sum);

    if (!storm && dayTotal >= 0.02) {
      storm = true;
      return sum + dayTotal;
    } else if (!storm && dayTotal < 0.02 && Number(day.dateTime) < today) {
      return 0;
    } else if (storm && dayTotal > 0) {
      return sum + dayTotal;
    } else if (storm && dayTotal === 0) {
      storm = false;
      return sum;
    } else {
      return sum;
    }
  }, 0);

  return stormTotal === 0 ? 'NA' : stormTotal;
}

export function getBarometerTrendInfo(threeHoursAgo, now) {
  const difference = Number(now) - Number(threeHoursAgo);

  if (difference >= 0.06) {
    return { barometerTrend: 'Rising Rapidly' };
  } else if (difference >= 0.02) {
    return { barometerTrend: 'Rising Slowly' };
  } else if (difference > -0.02) {
    return { barometerTrend: 'Steady' };
  } else if (difference > -0.06) {
    return { barometerTrend: 'Falling Slowly' };
  } else if (difference <= -0.06) {
    return { barometerTrend: 'Falling Rapidly' };
  }
}

export function getWindInfo(speed, degrees) {
  const direction = !degrees
    ? ''
    : WIND_DIRECTIONS[Math.floor(Number.parseInt(degrees, 10) / 22.5)];

  if (speed === '0' && !degrees) {
    return 'Calm';
  } else if (!degrees) {
    return `Variable at ${formatToFixed(speed, 1)} mph`;
  } else {
    return direction + ` at ${formatToFixed(speed, 1)} mph`;
  }
}

export function getWindDirection(degrees) {
  return !degrees
    ? '---'
    : WIND_DIRECTIONS[Math.floor(Number.parseInt(degrees, 10) / 22.5)];
}

export function toCelsius(fahrenheit) {
  return formatToFixed((fahrenheit - 32) * (5 / 9), 1);
}

export function getMillibarsFromSeaLevelPressure(inches) {
  inches = parseFloat(inches);
  return formatToFixed(inches / 0.02953, 1);
}

export function getMonthlyRain(rainTotals) {
  const monthlyRain = rainTotals
    .filter(
      day => Number(day.dateTime) >= DateTime.local().startOf('month').ts / 1000
    )
    .reduce((sum, day) => {
      return sum + Number(day.sum);
    }, 0);

  return `${formatToFixed(monthlyRain, 2)} in`;
}

export function getYearlyRain(rainTotals) {
  const yearlyRain = rainTotals.reduce((sum, day) => {
    return sum + Number(day.sum);
  }, 0);

  return `${formatToFixed(yearlyRain, 2)} in`;
}
