import PropTypes from 'prop-types';
import styled from 'styled-components';

const PresetButton = styled.div`
  transition: border 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;

  box-sizing: border-box;
  padding: 5px 10px;
  border: 1px solid var(--border);

  text-align: center;
  font-size: 0.9rem;

  background-color: var(--background-light);

  cursor: pointer;

  &:hover {
    transition: background-color 0.2s ease-in-out;

    background-color: var(--background-dark);
  }

  ${props =>
    props.selected
      ? `transition: border 0.2s ease-in-out;
    box-sizing: border-box;
    border: 1px solid var(--color-accent);
  `
      : ''};
`;

PresetButton.propTypes = {
  selected: PropTypes.bool.isRequired
};

export default PresetButton;
