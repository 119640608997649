import React, { Fragment } from 'react';
import DatePicker from 'material-ui-pickers/DatePicker';
import TimePicker from 'material-ui-pickers/TimePicker';
import { DateTime } from 'luxon';
import Loading from '../Common/Loading';
import './History.css';
import StationInfo from '../Common/StationInfo';
import WeatherStationDataItem from '../Common/WeatherStationDataItem';
import DateRange from './DateRange';
import TemperatureRadial from '../TemperatureRadial';
import {
  formatTemperatureChange,
  getUnixTimestamp,
  formatDate,
  formatTime,
  formatToFixed
} from '../../helpers/format';
import {
  getBarometerTrendInfo,
  getMillibarsFromSeaLevelPressure,
  getStormTotal,
  getWindInfo,
  toCelsius
} from '../../helpers/weather';

class History extends React.Component {
  getDefaultDateTime = () => {
    const oneDayAgo = DateTime.local().minus({ days: 1 });
    const newDate = oneDayAgo
      .minus({ minutes: oneDayAgo.minute % 5 })
      .startOf('minute');

    return newDate;
  };

  state = {
    dateTime: this.getDefaultDateTime(),
    rangeBegin: DateTime.local().minus({ days: 1 }).startOf('day'),
    rangeEnd: DateTime.local().minus({ days: 1 }).startOf('day'),
    rangeData: {},
    historyError: '',
    dateRangeError: '',
    dateTimeDataLoading: true,
    dateRangeDataLoading: true
  };

  roundToNearestFiveMinutes = (dateTime) => {
    const modFive = dateTime.minute % 5;
    const minutesToAdd =
      modFive === 0 ? 0 : modFive > 2 ? 5 - modFive : modFive * -1;

    return dateTime.plus({ minutes: minutesToAdd });
  };

  handleDateChange = (newDate) => {
    this.setState({ dateTime: newDate }, () => {
      this.getHistoricalDataForDateTime(this.state.dateTime);
    });
  };

  handleTimeChange = (newDate) => {
    if (newDate > DateTime.local()) {
      this.setState({
        historyError: 'Date and time cannot be in the future.'
      });

      return;
    } else {
      this.setState({
        historyError: ''
      });
    }

    this.setState({ dateTime: this.roundToNearestFiveMinutes(newDate) }, () => {
      this.getHistoricalDataForDateTime(this.state.dateTime);
    });
  };

  changeDateRange = (rangeBegin, rangeEnd) => {
    this.setState({ rangeBegin, rangeEnd }, () => {
      this.getHistoricalDataForRange(rangeBegin, rangeEnd);
    });
  };

  handleBeginDateChange = (newDate) => {
    this.setState({ rangeBegin: newDate }, () => {
      this.getHistoricalDataForRange(
        this.state.rangeBegin,
        this.state.rangeEnd
      );
    });
  };

  handleBeginTimeChange = (newDate) => {
    this.setState(
      { rangeBegin: this.roundToNearestFiveMinutes(newDate) },
      () => {
        this.getHistoricalDataForRange(
          this.state.rangeBegin,
          this.state.rangeEnd
        );
      }
    );
  };

  handleEndDateChange = (newDate) => {
    this.setState({ rangeEnd: newDate }, () => {
      this.getHistoricalDataForRange(
        this.state.rangeBegin,
        this.state.rangeEnd
      );
    });
  };

  handleEndTimeChange = (newDate) => {
    this.setState({ rangeEnd: this.roundToNearestFiveMinutes(newDate) }, () => {
      this.getHistoricalDataForRange(
        this.state.rangeBegin,
        this.state.rangeEnd
      );
    });
  };

  getHistoricalDataForRange = (begin, end) => {
    if (begin > end) {
      this.setState({
        dateRangeError: 'End date/time must be after begin date/time.'
      });
    } else if (begin.year < 2018 || end.year < 2018) {
      this.setState({
        dateRangeError: 'Data does not exist prior to January 1, 2018.'
      });
    } else {
      this.setState({ dateRangeError: '' });
    }

    let fetchUrl;

    if (
      begin.hour !== 0 ||
      end.hour !== 0 ||
      begin.minute !== 0 ||
      end.minute !== 0
    ) {
      fetchUrl = process.env.REACT_APP_HISTORY_ARCHIVE_HIGHS_LOWS;
    } else {
      fetchUrl = process.env.REACT_APP_HISTORY_HIGHS_LOWS;
    }

    fetch(
      `${fetchUrl}&begin=${getUnixTimestamp(begin)}&end=${getUnixTimestamp(
        end
      )}`
    )
      .then((response) => response.json())
      .then((json) => {
        const data = json.reduce((data, item) => {
          item.max = item.max === null ? null : Number(item.max);
          item.maxTime = item.maxTime === null ? null : Number(item.maxTime);
          item.min = item.min === null ? null : Number(item.min);
          item.minTime = item.minTime === null ? null : Number(item.minTime);
          item.value = item.value === null ? null : Number(item.value);

          data[item.property] = item;

          return data;
        }, {});

        this.setState({ rangeData: data, dateRangeDataLoading: false });
      });
  };

  getHistoricalDataForDateTime = (dateTime) => {
    if (dateTime.minute % 5 !== 0) {
      this.setState({
        dateRangeError:
          'The minutes associated with the time must be a multiple of 5.'
      });
    } else {
      this.setState({ dateRangeError: '' });
    }

    const dateTimeWeather = fetch(
      `${process.env.REACT_APP_WEATHER_AT_TIME}&dateTime=${getUnixTimestamp(
        dateTime
      )}`
    );
    const yearlyRainTotals = fetch(process.env.REACT_APP_YEARLY_RAIN_TOTALS);
    const stormTotalData = fetch(process.env.REACT_APP_STORM_TOTAL_DATA);

    Promise.all([dateTimeWeather, yearlyRainTotals, stormTotalData]).then(
      (responses) => {
        Promise.all(responses.map((response) => response.json())).then(
          (json) => {
            this.setState({ dateTimeWeather: json[0][0] });
            this.setState({ yearlyRainTotals: json[1] });

            this.setState(() => {
              const stormTotal = getStormTotal(json[2]);

              return {
                stormTotal:
                  stormTotal === 'NA'
                    ? 'NA'
                    : `${formatToFixed(stormTotal, 2)} in`
              };
            });

            const oneHourAgo =
              DateTime.fromMillis(json[0][0].dateTime * 1000).minus({
                minutes: 55
              }).ts / 1000;

            const threeHoursAgo =
              DateTime.fromMillis(json[0][0].dateTime * 1000).minus({
                hours: 3
              }).ts / 1000;

            const twentyFourHoursAgo =
              DateTime.fromMillis(json[0][0].dateTime * 1000).minus({
                hours: 24
              }).ts / 1000;

            Promise.all([
              fetch(
                `${
                  process.env.REACT_APP_ARCHIVE_WITHIN_RANGE
                }&begin=${oneHourAgo}&end=${getUnixTimestamp(
                  DateTime.fromMillis(json[0][0].dateTime * 1000)
                )}&sortDirection=DESC`
              ),
              fetch(
                `${process.env.REACT_APP_WEATHER_AT_TIME}&dateTime=${threeHoursAgo}`
              ),
              fetch(
                `${process.env.REACT_APP_WEATHER_AT_TIME}&dateTime=${twentyFourHoursAgo}`
              )
            ]).then((responses) => {
              Promise.all(responses.map((response) => response.json())).then(
                (json) => {
                  this.setState((state) => {
                    return {
                      lastHourArchiveData: json[0]
                    };
                  });

                  this.setState((state) => {
                    return getBarometerTrendInfo(
                      json[1][0].barometer,
                      state.dateTimeWeather.barometer
                    );
                  });

                  this.setState((state) => {
                    return {
                      twentyFourHourTempDifference:
                        state.dateTimeWeather.outTemp - json[2][0].outTemp,
                      dateTimeDataLoading: false
                    };
                  });
                }
              );
            });
          }
        );
      }
    );
  };

  getWindHigh = (max, maxTime) => {
    if (max === 0 || max === null) {
      return <div className="high">NA</div>;
    } else {
      return (
        <div className="high">
          <div>{`${formatToFixed(max, 1)} mph`}</div>
          <div className="date">{formatDate(maxTime)}</div>
          <div className="time">{formatTime(maxTime)}</div>
        </div>
      );
    }
  };

  getRainRateHigh = (max, maxTime) => {
    if (max === 0 || max === null) {
      return <div className="high">NA</div>;
    } else {
      return (
        <div className="high">
          <div>{`${formatToFixed(max, 2)} in/hr`}</div>
          <div className="date">{formatDate(maxTime)}</div>
          <div className="time">{formatTime(maxTime)}</div>
        </div>
      );
    }
  };

  getWindChillLow = (min, minTime) => {
    if (min > 50 || min === null) {
      return <div className="low">NA</div>;
    } else {
      return (
        <div className="low">
          <div>{`${formatToFixed(
            this.state.rangeData.windChill.min,
            1
          )}°F`}</div>
          <div className="date">
            {formatDate(this.state.rangeData.windChill.minTime)}
          </div>
          <div className="time">
            {formatTime(this.state.rangeData.windChill.minTime)}
          </div>
        </div>
      );
    }
  };

  getHeatIndexHigh = (max, maxTime) => {
    if (max < 60 || max === null) {
      return <div className="high">NA</div>;
    } else {
      return (
        <div className="high">
          <div>{`${formatToFixed(max, 1)}°F`}</div>
          <div className="date">{formatDate(maxTime)}</div>
          <div className="time">{formatTime(maxTime)}</div>
        </div>
      );
    }
  };

  componentDidMount() {
    this.getHistoricalDataForDateTime(this.state.dateTime);
    this.getHistoricalDataForRange(this.state.rangeBegin, this.state.rangeEnd);
  }

  render() {
    if (this.state.dateTimeDataLoading || this.state.dateRangeDataLoading) {
      return (
        <div className="loading">
          <Loading />
        </div>
      );
    }

    const today = DateTime.local();

    return (
      <div id="History">
        <StationInfo
          name="Castle Valley - Castle Pines, CO"
          latitude="39.47°N"
          longitude="-104.88°W"
          elevation="6394 ft"
        />
        {/* Specific Date & Time */}
        <div className="history-date-time">
          <div>Date & Time</div>
          <div className="history-date-time-pickers">
            <DatePicker
              allowKeyboardControl={false}
              className="history-date-time-begin"
              leftArrowIcon="<"
              rightArrowIcon=">"
              autoOk={true}
              minDate={DateTime.local(2018, 1, 1).startOf('year')}
              maxDate={today.startOf('day')}
              format={'LL/dd/yyyy'}
              value={this.state.dateTime.startOf('day')}
              onChange={this.handleDateChange}
            />
            <TimePicker
              id="history-date-time-begin-time"
              autoOk={true}
              format="hh:mm a"
              value={this.state.dateTime}
              onChange={this.handleTimeChange}
            />
          </div>
          {this.state.historyError !== '' ? (
            <div className="history-error">{this.state.historyError}</div>
          ) : (
            ''
          )}
          {/* <WeatherStation
            weather={this.state.dateTimeWeather}
            todaysRain={this.state.yearlyRainTotals[0]}
            lastFifteenMinutesRain={this.state.lastHourArchiveData
              .slice(0, 3)
              .reduce((sum, record) => sum + +record.rain, 0)}
            lastHoursRain={this.state.lastHourArchiveData.reduce(
              (sum, record) => sum + +record.rain,
              0
            )}
            stormTotal={this.state.stormTotal}
            twentyFourHourTempDifference={
              this.state.twentyFourHourTempDifference
            }
            barometerTrend={this.state.barometerTrend}
          /> */}
        </div>
        {this.state.historyError === '' && (
          <div className="history-date-time-data">
            <WeatherStationDataItem
              nameOne="Temperature"
              valueOne={`${formatToFixed(
                this.state.dateTimeWeather.outTemp,
                1
              )}°F`}
            />
            <WeatherStationDataItem
              nameOne="Humidity"
              valueOne={`${this.state.dateTimeWeather.outHumidity}%`}
            />
            <WeatherStationDataItem
              nameOne="Dewpoint"
              valueOne={`${formatToFixed(
                this.state.dateTimeWeather.dewpoint,
                1
              )}°F`}
            />
            <WeatherStationDataItem
              nameOne="Wind"
              valueOne={getWindInfo(
                this.state.dateTimeWeather.windSpeed,
                this.state.dateTimeWeather.windDir
              )}
            />
            <WeatherStationDataItem
              nameOne="Wind Gust"
              valueOne={`${formatToFixed(
                this.state.dateTimeWeather.windGust,
                1
              )} mph`}
            />
            <WeatherStationDataItem
              nameOne="Barometer"
              valueOne={`${formatToFixed(
                this.state.dateTimeWeather.barometer,
                2
              )} in & ${this.state.barometerTrend}`}
              valueTwo={`${getMillibarsFromSeaLevelPressure(
                formatToFixed(this.state.dateTimeWeather.barometer, 2)
              )} mb`}
            />
            <WeatherStationDataItem
              nameOne="Wind Chill"
              valueOne={`${
                Number(this.state.dateTimeWeather.windchill) <= 50
                  ? formatToFixed(
                      Number(this.state.dateTimeWeather.windchill),
                      1
                    ) + '°F'
                  : 'NA'
              }`}
            />
            <WeatherStationDataItem
              nameOne="Heat Index"
              valueOne={`${
                this.state.dateTimeWeather.heatindex >= 60
                  ? formatToFixed(this.state.dateTimeWeather.heatindex, 1) +
                    '°F'
                  : 'NA'
              }`}
            />
            <WeatherStationDataItem
              nameOne="24-Hour Temp Change"
              valueOne={formatTemperatureChange(
                this.state.twentyFourHourTempDifference
              )}
            />
            <WeatherStationDataItem
              nameOne="Precipitation"
              nameTwo="(last 5 / 15 / 60 min)"
              valueOne={`${formatToFixed(
                this.state.dateTimeWeather.rain,
                2
              )} / ${formatToFixed(
                this.state.lastHourArchiveData
                  .slice(0, 3)
                  .reduce((sum, record) => sum + +record.rain, 0),
                2
              )} / ${formatToFixed(
                this.state.lastHourArchiveData.reduce(
                  (sum, record) => sum + +record.rain,
                  0
                ),
                2
              )} in`}
            />
            <WeatherStationDataItem
              nameOne="Rain Rate"
              valueOne={`${formatToFixed(
                this.state.dateTimeWeather.rainRate,
                2
              )} in/hr`}
            />
            <WeatherStationDataItem
              nameOne="Storm Total"
              valueOne={this.state.stormTotal}
            />
            <WeatherStationDataItem
              nameOne="Today's Precip"
              valueOne={`${formatToFixed(
                this.state.yearlyRainTotals[0].sum,
                2
              )} in`}
            />
            {/* <WeatherStationDataItem
          nameOne="Monthly Rain"
          valueOne={getMonthlyRain(props.rainTotals)}
        /> */}
            {/* <WeatherStationDataItem
          nameOne="Yearly Rain"
          valueOne={getYearlyRain(props.rainTotals)}
        /> */}
            {/* <WeatherStationDataItem
              nameOne="Altimeter"
              valueOne={this.state.dateTimeWeather.altimeter}
            /> */}
            {/* <WeatherStationDataItem
              nameOne="Inside Temperature"
              valueOne={this.state.dateTimeWeather.inTemp}
            /> */}
            {/* <WeatherStationDataItem
              nameOne="Inside Humidity"
              valueOne={this.state.dateTimeWeather.inHumidity}
            /> */}
          </div>
        )}
        <DateRange
          begin={this.state.rangeBegin}
          end={this.state.rangeEnd}
          handleBeginDateChange={this.handleBeginDateChange}
          handleBeginTimeChange={this.handleBeginTimeChange}
          handleEndDateChange={this.handleEndDateChange}
          handleEndTimeChange={this.handleEndTimeChange}
          changeDateRange={this.changeDateRange}
        />
        {this.state.dateRangeError !== '' ? (
          <div className="date-range-error">{this.state.dateRangeError}</div>
        ) : (
          <Fragment>
            <div className="history-temperatures">
              <TemperatureRadial
                fahrenheitValue={formatToFixed(
                  this.state.rangeData.temperature.max,
                  1
                )}
                celsiusValue={formatToFixed(
                  toCelsius(this.state.rangeData.temperature.max),
                  1
                )}
                size={220}
                innerArcInnerRadius={92}
                innerArcOuterRadius={99}
                outerArcInnerRadius={104}
                outerArcOuterRadius={107}
                mainText="High"
                mainTextTranslation="-35"
                fahrenheitFontSize="25pt"
                celsiusFontSize="13pt"
                celsiusTranslation="35"
              />
              <TemperatureRadial
                fahrenheitValue={formatToFixed(
                  this.state.rangeData.temperature.min,
                  1
                )}
                celsiusValue={formatToFixed(
                  toCelsius(this.state.rangeData.temperature.min),
                  1
                )}
                size={220}
                innerArcInnerRadius={92}
                innerArcOuterRadius={99}
                outerArcInnerRadius={104}
                outerArcOuterRadius={107}
                mainText="Low"
                mainTextTranslation="-35"
                fahrenheitFontSize="25pt"
                celsiusFontSize="13pt"
                celsiusTranslation="35"
              />
            </div>
            <div className="history-data">
              <div className="header" key="historicalData-header">
                <div className="description" />
                <div className="high">High</div>
                <div className="low">Low</div>
              </div>
              {/* Temperature */}
              <div className="row">
                <div className="description">
                  {this.state.rangeData.temperature.description}
                </div>
                <div className="high">
                  <div>{`${formatToFixed(
                    this.state.rangeData.temperature.max,
                    1
                  )}°F`}</div>
                  <div className="date">
                    {formatDate(this.state.rangeData.temperature.maxTime)}
                  </div>
                  <div className="time">
                    {formatTime(this.state.rangeData.temperature.maxTime)}
                  </div>
                </div>
                <div className="low">
                  <div>{`${formatToFixed(
                    this.state.rangeData.temperature.min,
                    1
                  )}°F`}</div>
                  <div className="date">
                    {formatDate(this.state.rangeData.temperature.minTime)}
                  </div>
                  <div className="time">
                    {formatTime(this.state.rangeData.temperature.minTime)}
                  </div>
                </div>
              </div>
              {/* Humidity */}
              <div className="row">
                <div className="description">
                  {this.state.rangeData.humidity.description}
                </div>
                <div className="high">
                  <div>{`${this.state.rangeData.humidity.max}%`}</div>
                  <div className="date">
                    {formatDate(this.state.rangeData.humidity.maxTime)}
                  </div>
                  <div className="time">
                    {formatTime(this.state.rangeData.humidity.maxTime)}
                  </div>
                </div>
                <div className="low">
                  <div>{`${this.state.rangeData.humidity.min}%`}</div>
                  <div className="date">
                    {formatDate(this.state.rangeData.humidity.minTime)}
                  </div>
                  <div className="time">
                    {formatTime(this.state.rangeData.humidity.minTime)}
                  </div>
                </div>
              </div>
              {/* Dewpoint */}
              <div className="row">
                <div className="description">
                  {this.state.rangeData.dewpoint.description}
                </div>
                <div className="high">
                  <div>{`${formatToFixed(
                    this.state.rangeData.dewpoint.max,
                    1
                  )}°F`}</div>
                  <div className="date">
                    {formatDate(this.state.rangeData.dewpoint.maxTime)}
                  </div>
                  <div className="time">
                    {formatTime(this.state.rangeData.dewpoint.maxTime)}
                  </div>
                </div>
                <div className="low">
                  <div>{`${formatToFixed(
                    this.state.rangeData.dewpoint.min,
                    1
                  )}°F`}</div>
                  <div className="date">
                    {formatDate(this.state.rangeData.dewpoint.minTime)}
                  </div>
                  <div className="time">
                    {formatTime(this.state.rangeData.dewpoint.minTime)}
                  </div>
                </div>
              </div>
              {/* Wind */}
              <div className="row">
                <div className="description">
                  {this.state.rangeData.wind.description}
                </div>
                {this.getWindHigh(
                  this.state.rangeData.wind.max,
                  this.state.rangeData.wind.maxTime
                )}
                <div className="low">---</div>
              </div>
              {/* Barometer */}
              <div className="row">
                <div className="description">
                  {this.state.rangeData.barometer.description}
                </div>
                <div className="high">
                  <div>{`${formatToFixed(
                    this.state.rangeData.barometer.max,
                    2
                  )} in`}</div>
                  <div>{`${getMillibarsFromSeaLevelPressure(
                    this.state.rangeData.barometer.max
                  )} mb`}</div>
                  <div className="date">
                    {formatDate(this.state.rangeData.barometer.maxTime)}
                  </div>
                  <div className="time">
                    {formatTime(this.state.rangeData.barometer.maxTime)}
                  </div>
                </div>
                <div className="low">
                  <div>{`${formatToFixed(
                    this.state.rangeData.barometer.min,
                    2
                  )} in`}</div>
                  <div>{`${getMillibarsFromSeaLevelPressure(
                    this.state.rangeData.barometer.min
                  )} mb`}</div>
                  <div className="date">
                    {formatDate(this.state.rangeData.barometer.minTime)}
                  </div>
                  <div className="time">
                    {formatTime(this.state.rangeData.barometer.minTime)}
                  </div>
                </div>
              </div>
              {/* Precipitation Total */}
              <div className="row">
                <div className="description">
                  {this.state.rangeData.precipitationTotal.description}
                </div>
                <div className="high">
                  <div>{`${formatToFixed(
                    this.state.rangeData.precipitationTotal.value,
                    2
                  )} in`}</div>
                </div>
                <div className="low">---</div>
              </div>
              {/* Rain Rate */}
              <div className="row">
                <div className="description">
                  {this.state.rangeData.rainRate.description}
                </div>
                {this.getRainRateHigh(
                  this.state.rangeData.rainRate.max,
                  this.state.rangeData.rainRate.maxTime
                )}
                <div className="low">---</div>
              </div>
              {/* Wind Chill */}
              <div className="row">
                <div className="description">
                  {this.state.rangeData.windChill.description}
                </div>
                <div className="high">---</div>
                {this.getWindChillLow(
                  this.state.rangeData.windChill.min,
                  this.state.rangeData.windChill.minTime
                )}
              </div>
              {/* Heat Index */}
              <div className="row">
                <div className="description">
                  {this.state.rangeData.heatIndex.description}
                </div>
                {this.getHeatIndexHigh(
                  this.state.rangeData.heatIndex.max,
                  this.state.rangeData.heatIndex.maxTime
                )}
                <div className="low">---</div>
              </div>
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

export default History;
