import React from 'react';
import DatePicker from 'material-ui-pickers/DatePicker';
import TimePicker from 'material-ui-pickers/TimePicker';
import PresetButton from '../Common/PresetButton';
import { DateTime } from 'luxon';
import './DateRange.css';

class DateRange extends React.Component {
  isToday = () => {
    return (
      this.props.begin.toMillis() === this.props.end.toMillis() &&
      this.props.begin.toMillis() ===
        DateTime.local()
          .startOf('day')
          .toMillis()
    );
  };

  isYesterday = () => {
    return (
      this.props.begin.toMillis() === this.props.end.toMillis() &&
      this.props.begin.toMillis() ===
        DateTime.local()
          .minus({ days: 1 })
          .startOf('day')
          .toMillis()
    );
  };

  isThisMonth = () => {
    return (
      this.props.begin.toMillis() ===
        DateTime.local()
          .startOf('month')
          .toMillis() &&
      this.props.end.toMillis() ===
        DateTime.local()
          .startOf('day')
          .toMillis()
    );
  };

  isLastMonth = () => {
    return (
      this.props.begin.toMillis() ===
        DateTime.local()
          .minus({ months: 1 })
          .startOf('month')
          .toMillis() &&
      this.props.end.toMillis() ===
        DateTime.local()
          .minus({ months: 1 })
          .endOf('month')
          .startOf('day')
          .toMillis()
    );
  };

  isThisYear = () => {
    return (
      this.props.begin.toMillis() ===
        DateTime.local()
          .startOf('year')
          .toMillis() &&
      this.props.end.toMillis() ===
        DateTime.local()
          .startOf('day')
          .toMillis()
    );
  };

  isLastYear = () => {
    return (
      this.props.begin.toMillis() ===
        DateTime.local()
          .minus({ years: 1 })
          .startOf('year')
          .toMillis() &&
      this.props.end.toMillis() ===
        DateTime.local()
          .endOf('year')
          .minus({ years: 1 })
          .startOf('day')
          .toMillis()
    );
  };

  onPresetClick = preset => event => {
    switch (preset) {
      case 'Today':
        this.props.changeDateRange(
          DateTime.local().startOf('day'),
          DateTime.local().startOf('day')
        );
        break;

      case 'This Month':
        this.props.changeDateRange(
          DateTime.local().startOf('month'),
          DateTime.local().startOf('day')
        );
        break;

      case 'This Year':
        this.props.changeDateRange(
          DateTime.local().startOf('year'),
          DateTime.local().startOf('day')
        );
        break;

      case 'Yesterday':
        const yesterday = DateTime.local().minus({ days: 1 });

        this.props.changeDateRange(
          yesterday.startOf('day'),
          yesterday.startOf('day')
        );
        break;

      case 'Last Month':
        const lastMonth = DateTime.local().minus({ months: 1 });

        this.props.changeDateRange(
          lastMonth.startOf('month'),
          lastMonth.endOf('month').startOf('day')
        );
        break;

      case 'Last Year':
        const lastYear = DateTime.local().minus({ years: 1 });

        this.props.changeDateRange(
          lastYear.startOf('year'),
          lastYear.endOf('year').startOf('day')
        );
        break;

      default:
        break;
    }
  };

  render() {
    const today = DateTime.local();

    return (
      <div className="DateRange">
        <div className="presets">
          <PresetButton
            selected={this.isToday()}
            onClick={this.onPresetClick('Today')}
          >
            Today
          </PresetButton>
          <PresetButton
            selected={this.isThisMonth()}
            onClick={this.onPresetClick('This Month')}
          >
            This Month
          </PresetButton>
          <PresetButton
            selected={this.isThisYear()}
            onClick={this.onPresetClick('This Year')}
          >
            This Year
          </PresetButton>
          <PresetButton
            selected={this.isYesterday()}
            onClick={this.onPresetClick('Yesterday')}
          >
            Yesterday
          </PresetButton>
          <PresetButton
            selected={this.isLastMonth()}
            onClick={this.onPresetClick('Last Month')}
          >
            Last Month
          </PresetButton>
          <PresetButton
            selected={this.isLastYear()}
            onClick={this.onPresetClick('Last Year')}
          >
            Last Year
          </PresetButton>
        </div>
        <div className="date-range-title">Date & Time Range</div>
        <div className="pickers">
          <DatePicker
            allowKeyboardControl={false}
            className="begin"
            leftArrowIcon="<"
            rightArrowIcon=">"
            autoOk={true}
            minDate={DateTime.local(2018, 1, 1).startOf('year')}
            maxDate={today}
            format={'LL/dd/yyyy'}
            value={this.props.begin}
            onChange={this.props.handleBeginDateChange}
          />
          <TimePicker
            id="date-range-begin-time"
            autoOk={true}
            format="hh:mm a"
            value={this.props.begin}
            onChange={this.props.handleBeginTimeChange}
            disabled={!this.props.begin.hasSame(this.props.end, 'day')}
          />
          <DatePicker
            allowKeyboardControl={false}
            className="end"
            leftArrowIcon="<"
            rightArrowIcon=">"
            autoOk={true}
            minDate={DateTime.local(2018, 1, 1).startOf('year')}
            maxDate={today}
            format={'LL/dd/yyyy'}
            value={this.props.end}
            onChange={this.props.handleEndDateChange}
          />
          <TimePicker
            id="date-range-end-time"
            autoOk={true}
            format="hh:mm a"
            value={this.props.end}
            onChange={this.props.handleEndTimeChange}
            disabled={!this.props.begin.hasSame(this.props.end, 'day')}
          />
        </div>
      </div>
    );
  }
}

export default DateRange;
